import React from 'react'
import { isValidUrl } from '../../../utils/functions/slugs'
import { isTestEnv } from '../../../utils/functions/isTestEnv'
const isTest = isTestEnv()

const Link = (props: LinkProps = linkDefaultProps) => {
  const {
    children,
    isExternal,
    isJumpLinkOrHashFragment,
    navigateToRoute,
    href,
    style,
    rel,
    className
  } = props
  if (!isExternal && !isValidUrl(href, isJumpLinkOrHashFragment)) {
    return null
  }

  if (!isExternal) {
    const elementType = children?.type ?? 'a'
    const newChildren = React.createElement(elementType, {
      ...children.props,
      href: elementType === 'a' ? href ?? null : null,
      style: style ?? {},
      onClick: (e: Event) => {
        e.preventDefault()
        if (href) {
          if (navigateToRoute) {
            navigateToRoute(e, href)
          } else {
            window.location.href = href
          }
        }
      }
    })

    return <>{newChildren}</>
  }

  const relObject = rel ? { rel } : {}
  const externalLink = {
    ...children,
    props: children.props
      ? {
          ...children.props,
          href: href,
          target: '_blank',
          rel: 'noopener noreferrer',
          ...relObject, // let others overrite if they want different
          style: style ?? {},
          'data-testid': isTest ? props['data-testid'] : undefined
        }
      : null
  }

  return children?.type === 'a' ? (
    <>{externalLink}</>
  ) : (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      {...relObject} // let others overrite if they want different
      style={style ?? {}}
      className={className ?? {}}
      data-testid={isTest ? props['data-testid'] : undefined}
    >
      {children}
    </a>
  )
}

type LinkProps = {
  href: string
  children?: any
  style?: any
  className?: any
  'data-testid'?: any
  isExternal?: boolean
  isJumpLinkOrHashFragment?: boolean
  rel?: string
  navigateToRoute?: (e: Event, href: string) => void
}

export const linkDefaultProps = {
  href: '',
  children: {},
  isExternal: false,
  isJumpLink: false,
  rel: '',
  isJumpLinkOrHashFragment: false,
  navigateToRoute: undefined,
  className: ''
}

export default Link
