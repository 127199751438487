import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import some from 'lodash/some'
import logger from '../logger'
import { linesFromStack } from '../stackTrace'
import { getRuntimeEnv } from './get-runtime-env'

export const customPagesSlugs = [
  'news',
  'reviews',
  'caradvice',
  'showrooms',
  'our-journalists',
  'our-ratings-explained'
]

export const FALLBACK = 'blocking'

/**
 * Get Cars for sale single listing URL.
 *
 * The reason we are keeping this in a function so that
 * if we happen to change the url later we can do it at one place.
 *
 * @param {Integer} id ID.
 * @returns {string}
 */
export const getSingleListingURL = (id) => {
  if (!id) {
    return ''
  }
  return `/cars-for-sale/car/${id}/`
}

/**
 * Check if its a valid url.
 *
 * Checks the following conditions.
 * 1. URL is not empty.
 * 2. URL does not contain ' ', '//'( excluding the one after https ), 'null', 'undefined'
 * 3. Should have leading and trailing slash
 * 4. Should not have double slash except after http or https
 * 5. For Jump Links does not checks trailing slash after the #.
 *
 * @param {String} url URL.
 * @param {Boolean} isJumpLinkOrHashFragment Is Jump Link or Hash Fragment.
 * @returns {boolean}
 */
export const isValidUrl = (url, isJumpLinkOrHashFragment = false) => {
  // If the url is empty or does not contain a trailing slash, return false.
  const doesNotHaveTrailingSlash = isJumpLinkOrHashFragment
    ? !url?.match(/^.*\/(#.*)?$/)
    : !url?.includes('utm_') && url?.slice(-1) !== '/'

  if (
    url == null ||
    ((isEmpty(url) || doesNotHaveTrailingSlash) &&
      !url.startsWith('mailto') &&
      !url.includes('adclick.g.doubleclick.net'))
  ) {
    return false
  }

  const invalidStrings = [' ', '//', '/%20/']
  let pathname = url
  if (pathname.includes('www') || pathname.includes('http')) {
    try {
      const anchor = new URL(url)
      pathname = anchor.pathname
      if (anchor.origin.includes('adclick.g.doubleclick.net') && pathname?.slice(-1) !== '/') {
        pathname = pathname + '/'
      }
    } catch (error) {
      logger.warn(
        `[App Error] - slugs.js URLParseError - [${url}] - ${linesFromStack(new Error().stack, 3)}`
      )
    }
  }
  pathname = pathname?.includes('utm_') ? pathname?.split('?')?.[0] : pathname // extracting out pathname only when its a url with utm_ query link
  let isNotValid =
    pathname.match(/^null$|^undefined$/g) || invalidStrings.some((item) => pathname.includes(item))

  // Check if it contains the leading and trailing slash
  if (!isNotValid && !url.startsWith('mailto')) {
    const noTrailingOrLeadingSlash = !isJumpLinkOrHashFragment
      ? pathname[0] !== '/' || pathname[pathname.length - 1] !== '/'
      : pathname[0] !== '/'
    if (noTrailingOrLeadingSlash) {
      isNotValid = true
    }
  }

  if (isNotValid) {
    logger.error(
      `[App Error] - slugs.js InvalidUrl - [${pathname}] - ${linesFromStack(new Error().stack, 3)}`
    )
  }

  return !isNotValid
}

/**
 * Get current page url
 *
 * @param {String} slug Slug
 *
 * @return {String} Current page url
 */
export const getCurrentPageUrl = (slug) => {
  if (!slug?.startsWith('/')) return slug

  /* adds protocol and domain if it its a relative path
   * uses NEXT_PUBLIC_FRONTEND_URL
   * fallbacks to browser location origin
   */
  const currentLocation = typeof window !== 'undefined' ? window.location.origin : null
  return getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')
    ? `${getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')}${slug}`
    : `${currentLocation || ''}${slug}`
}

/**
 * Checks if the slug the wordpress matches with slugsToExclude array
 *
 * @param {String} slug Tag Slug
 *
 * @return {Boolean}
 */
export const doesSlugMatchesCustomTagPage = (slug) => {
  const slugsToExclude = ['current-release', 'sticky']

  return slugsToExclude.includes(slug)
}

/**
 * Checks if the pages URI from the wordpress matches with the list for page template with an aside
 *
 * @param {String} uri URI
 *
 * @return {Boolean}
 */
export const templateType = (uri) => {
  const pagesWithAside = ['/about-drive/community-guidelines/']

  return pagesWithAside.includes(uri)
}

/**
 * Get Formatted URL from Slugs.
 *
 * e.g. slugs =  ['state', 'region', 'model'] will output "/state/region/model/" as formatted url.
 *
 * @param {Array} slugs Slugs.
 * @returns {string} Formatted URL
 */
export const getFormattedUrlFromSlugs = (slugs) => {
  /** Check if all slugs are valid */
  if (isEmpty(slugs) || !isArray(slugs) || some(slugs, (slug) => isEmpty(slug))) {
    return ''
  }
  let formattedUrl = ''
  slugs.forEach((value) => {
    formattedUrl = `${formattedUrl}/${value}`
  })

  return `${formattedUrl}/`
}
